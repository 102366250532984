export {createModalSeriesRankingBlock};

function createModalSeriesRankingBlock(data, options) {
  const $div = document.createElement('div');
  if(data.ranking.length > 0) {
    data.ranking.forEach($row => {
      const $div1 = document.createElement('div');
      $div1.classList.add('news-article');
      {
        const $a = document.createElement('a');
        $a.classList.add('row', 'gtm-click');
        $a.id = 'clickID-manga_recommend_click_' + $row.id;
        if (options.viewerSelector === 'modalSeriesRankingRecommendMangaViewer') {
          $a.id = 'clickID-manga2_recommend_click_' + $row.id;
        }
        $a.href = '/smilenews/newsseries/detail/' + $row.id;
        {
          const $div2 = document.createElement('div');
          $div2.classList.add('col-xs-4', 'col-sm-3','is-article-thumb','is-square');
          {
            const $figure = document.createElement('figure');
            $figure.classList.add('is-article-img-trim');
            {
              const $img = document.createElement('img');
              $img.classList.add('is-article-img', 'is-w100');
              $img.src = $row.images.square.small;
              $img.alt = $row.title;
              $img.loading = 'lazy';
              $figure.appendChild($img);
            }
            $div2.appendChild($figure);
          }
          $a.appendChild($div2);

          const $div3 = document.createElement('div');
          $div3.classList.add('col-xs-8', 'col-sm-9');
          {
            const $div_title = document.createElement('div');
            {
              const $p = document.createElement('p');
              $p.classList.add('title', 'is-3');
              $p.textContent = $row.title;
              $div_title.appendChild($p);
            }
            $div3.appendChild($div_title);

            const $div4 = document.createElement('div');
            if ($row.status === 1) {
              $div4.classList.add('label-round', 'is-palegreen', 'mr10');
              $div4.textContent = '完結';
            }else {
              $div4.classList.add('label-round', 'is-palepink', 'mr10');
              $div4.textContent = '連載中';
            }
            $div3.appendChild($div4);

            const $span = document.createElement('span');
            $span.classList.add('c-gray', 'fz-sm');
            $span.textContent = $row.total_episodes + '話';
            $div3.appendChild($span);
          }
          $a.appendChild($div3);
        }
        $div1.appendChild($a);
      }
      $div.appendChild($div1);
    });
  }
  return $div;
}

